<template>
    <v-container fluid></v-container>
</template>

<script>
export default {
    name: 'Enquiry',
    components: { },
    data: () => ({}),
    methods: {},
}
</script>